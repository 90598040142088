import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Section from "./Section";
import LinkedIn from "@material-ui/icons/LinkedIn";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subFooter: {
            width: "100%",
            display: "flex",
            fontSize: "0.875rem",
            letterSpacing: "0.03rem",
            lineHeight: 1.5,
            fontWeight: 400,
            alignItems: "center",
            "& div": {
                marginRight: "0.5rem",
                "&:not(:first-child)": {
                    marginLeft: "0.5rem",
                },
            },
        },
        copyright: {},
        action: {
            alignItems: "center",
            display: "flex",
            marginLeft: "0.5rem",
        },
    })
);

interface SubFooterProps {}

const SubFooter: React.FC<SubFooterProps> = (props) => {
    const classes = useStyles();
    return (
        <Section verticalSpacing={2} bgColor="#ECEFF1">
            <div className={classes.subFooter}>
                <div className={classes.copyright}>© 2020 Timothy Edgar</div>
                <div>|</div>
                <a
                    href="https://www.linkedin.com/in/timothyedgar/"
                    className={classes.action}
                    aria-label="LinkedIn"
                    title="LinkedIn"
                >
                    <LinkedIn color="action" />
                </a>
            </div>
        </Section>
    );
};

export default SubFooter;
