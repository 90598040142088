/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { ImageSearchOutlined } from "@material-ui/icons";
import { FixedObject } from "gatsby-image";

interface SEOProps {
    description?: string;
    meta?: JSX.IntrinsicElements["meta"][];
    title: string;
    article?: boolean;
    path?: string;
    date?: string;
}

interface QueryData {
    site: {
        siteMetadata: {
            title: string;
            description: string;
            author: string;
            siteUrl: string;
        };
    };
    icon: {
        childImageSharp: {
            fixed: FixedObject;
        };
    };
}

const SEO: React.FC<SEOProps> = (props) => {
    const { site, icon } = useStaticQuery<QueryData>(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
                icon: file(relativePath: { eq: "icon.png" }) {
                    childImageSharp {
                        fixed(width: 200, height: 200) {
                            ...GatsbyImageSharpFixed
                            src
                        }
                    }
                }
            }
        `
    );

    const metaDescription = props.description || site.siteMetadata.description;
    const url = props.path
        ? `${site.siteMetadata.siteUrl}${props.path}`
        : site.siteMetadata.siteUrl;
    const imageUrl = `${site.siteMetadata.siteUrl}${icon.childImageSharp.fixed.src}`;
    const additionalMeta = props.meta ?? [];

    const meta = additionalMeta
        .concat([
            {
                name: `description`,
                content: metaDescription,
            },
            {
                name: `author`,
                content: site.siteMetadata.author,
            },
            {
                property: `og:title`,
                content: props.title,
            },
            {
                property: `og:description`,
                content: metaDescription,
            },
            {
                property: `og:type`,
                content: props.article ? `article` : `website`,
            },
            {
                property: `og:url`,
                content: url,
            },
            {
                property: `og:image`,
                content: imageUrl,
            },
            {
                name: `twitter:card`,
                content: `summary`,
            },
            {
                name: `twitter:creator`,
                content: site.siteMetadata?.author || ``,
            },
            {
                name: `twitter:title`,
                content: props.title,
            },
            {
                name: `twitter:description`,
                content: metaDescription,
            },
        ])
        .concat(
            props.date
                ? [
                      {
                          name: `date`,
                          content: props.date,
                      },
                  ]
                : []
        );

    return (
        <Helmet
            htmlAttributes={{
                lang: "en",
            }}
            title={props.title}
            titleTemplate={`%s`}
            defaultTitle={`Timothy Edgar`}
            meta={meta}
        ></Helmet>
    );
};

export default SEO;
