import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: CenterProps) => {
            const maxWidth = props.maxWidth ?? 960 + theme.spacing(6);
            return {
                maxWidth: maxWidth,
                margin: "0 auto",
                width: "100%",

                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
                [theme.breakpoints.down("xs")]: {
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                },
            };
        },
    })
);

interface CenterProps {
    children?: React.ReactNode;
    component?: React.ElementType;
    flex?: boolean;
    maxWidth?: number;
}

const Center: React.FC<CenterProps> = (props) => {
    const classes = useStyles(props);
    const Component = props.component || "div";

    return <Component className={classes.root}>{props.children}</Component>;
};

export default Center;
