import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import Link from "@material-ui/core/Link";
import { Link as GatsbyLink } from "gatsby";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        link: {},
        label: {
            "&:hover": {
                color: theme.palette.grey.A700,
            },
        },
    })
);

interface HeaderDrawerLinkProps {
    label: string;
    to: string;
}

const HeaderDrawerLink: React.FC<HeaderDrawerLinkProps> = (props) => {
    const classes = useStyles(props);
    return (
        <ListItem button className={classes.root}>
            <Link
                component={GatsbyLink}
                color="inherit"
                underline="none"
                to={props.to}
                className={classes.link}
            >
                <ListItemText
                    primary={props.label}
                    primaryTypographyProps={{
                        variant: "button",
                    }}
                    className={classes.label}
                ></ListItemText>
            </Link>
        </ListItem>
    );
};

export default HeaderDrawerLink;
