import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import Link from "@material-ui/core/Link";
import { Link as GatsbyLink } from "gatsby";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: HeaderNavLinkProps) => ({
            textAlign: "center",
            minWidth: 120,
            display: "flex",
            alignItems: "center",
            height: "100%",
            borderBottom: props.selected
                ? `2px ${theme.palette.primary.main} solid`
                : `2px transparent solid`,
            "&:hover": {
                backgroundColor: "#fafafa",
                borderBottom: `2px ${theme.palette.primary.main} solid`,
            },
        }),
        link: {
            padding: theme.spacing(2),
            width: "100%",
        },
        label: (props: HeaderNavLinkProps) => ({
            "&:hover": {
                color: props.primary ? theme.palette.primary.main : "inherit",
            },
        }),
    })
);

interface HeaderNavLinkProps {
    label: string;
    selected?: boolean;
    to: string;
    primary?: boolean;
}

const HeaderNavLink: React.FC<HeaderNavLinkProps> = (props) => {
    const classes = useStyles(props);
    return (
        <div className={clsx(classes.root)}>
            <Link
                component={GatsbyLink}
                color="inherit"
                underline="none"
                to={props.to}
                className={classes.link}
            >
                <Typography variant="button" className={classes.label}>
                    {props.label}
                </Typography>
            </Link>
        </div>
    );
};

export default HeaderNavLink;
