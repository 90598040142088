import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
//import "./layout.css";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
        },
        main: {
            margin: "0 auto",
            maxWidth: 800,
            paddingTop: theme.spacing(10),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
        },
        wrapper: {
            marginLeft: "calc(100vw - 100%)",
            flexGrow: 1,
        },
    })
);

interface LayoutProps {
    section?: string;
    children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children, section }) => {
    const classes = useStyles();
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <div className={classes.root}>
            <Header
                siteTitle={data.site.siteMetadata?.title || `Timothy Edgar`}
                section={section}
            />
            <div className={classes.wrapper}>
                <main>{children}</main>
            </div>

            <Footer />
        </div>
    );
};

export default Layout;
