import AppBar from "@material-ui/core/AppBar";
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Link as GatsbyLink } from "gatsby";
import * as React from "react";
import HeaderNavLink from "./HeaderNavLink";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import HeaderDrawerLink from "./HeaderDrawerLink";
import HeaderNav from "./HeaderNav";
import Center from "./Center";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: "calc(100vw - 100%)",
        },
        menuButton: {},
        title: {
            flexGrow: 1,
            color: "inherit",
            textDecoration: "none",
            textTransform: "uppercase",
            fontSize: "1rem",
        },
        center: {
            maxWidth: 960,
            margin: "0 auto",
            width: "100%",
        },
        nav: {
            display: "flex",
        },
        tabs: {
            height: 64,
        },
        drawer: {},
        drawerPaper: {
            width: drawerWidth,
        },
        toolbar: theme.mixins.toolbar,
    })
);

interface HeaderProps {
    siteTitle: string;
    section?: string;
}

const Header: React.FC<HeaderProps> = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("xs"));
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <AppBar
                position="static"
                color="transparent"
                className={classes.root}
            >
                <Center component="nav">
                    <Toolbar disableGutters>
                        <Typography variant="h6" className={classes.title}>
                            <Link
                                component={GatsbyLink}
                                to={`/`}
                                color="inherit"
                                underline="none"
                            >
                                Timothy Edgar
                            </Link>
                        </Typography>
                        <Hidden xsDown implementation="css">
                            <HeaderNav>
                                <HeaderNavLink
                                    label="Blog"
                                    to="/blog"
                                    selected={props.section == "blog"}
                                />
                                <HeaderNavLink
                                    label="About"
                                    to="/about"
                                    selected={props.section == "about"}
                                />
                            </HeaderNav>
                        </Hidden>
                        <Hidden smUp implementation="css">
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleDrawerOpen}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                    </Toolbar>
                </Center>
            </AppBar>
            <Hidden smUp implementation="js">
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={open}
                    onClose={handleDrawerClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <div>
                        <div className={classes.toolbar} />
                        <Divider />
                        <List>
                            <HeaderDrawerLink to="/blog" label="Blog" />
                            <HeaderDrawerLink to="/about" label="About" />
                        </List>
                    </div>
                </Drawer>
            </Hidden>
        </React.Fragment>
    );
};

export default Header;
