import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import Center from "./Center";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: SectionProps) => {
            const vSpacing = props.verticalSpacing ?? 4;
            const topSpacing = props.first ? 10 : props.paddingTop ?? vSpacing;
            const bottomSpacing = props.last
                ? 10
                : props.paddingBottom ?? vSpacing;
            return {
                paddingTop: theme.spacing(topSpacing),
                paddingBottom: theme.spacing(bottomSpacing),
                backgroundColor: props.bgColor || "#fff",
            };
        },
    })
);

interface SectionProps {
    children?: React.ReactNode;
    bgColor?: string;
    verticalSpacing?: number;
    paddingTop?: number;
    paddingBottom?: number;
    flex?: boolean;
    maxWidth?: number;
    first?: boolean;
    last?: boolean;
    content?: boolean;
    divider?: boolean;
}

const contentWidth = 700;

const Section: React.FC<SectionProps> = (props) => {
    const classes = useStyles(props);

    return (
        <section className={classes.root}>
            <Center
                flex={props.flex}
                maxWidth={props.content ? contentWidth : props.maxWidth}
            >
                {props.children}
            </Center>
        </section>
    );
};

export default Section;
