import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: HeaderNavProps) => ({
            display: "flex",
            margin: 0,
            padding: 0,
            alignItems: "center",
            height: "100%",
            flexGrow: props.grow ? 1 : 0,
        }),
    })
);

interface HeaderNavProps {
    children?: React.ReactNode;
    grow?: boolean;
}

const HeaderNav: React.FC<HeaderNavProps> = (props) => {
    const classes = useStyles(props);
    return <div className={classes.root}>{props.children}</div>;
};

export default HeaderNav;
