import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import SubFooter from "./SubFooter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
    })
);

interface FooterProps {}

const Footer: React.FC<FooterProps> = (props) => {
    const classes = useStyles();
    return (
        <footer className={classes.root}>
            <SubFooter />
        </footer>
    );
};

export default Footer;
